import React, { useEffect } from "react";
import classes from "./layout.module.css";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logo from "../Assets/Images/metaMainLogo.png";
import defaultMainImage from "../Assets/Images/logindefaultImage.png";
import {
  CloudRaindBowIcon,
  LoginCodeIcon,
  LoginCrossButterFlyIcon,
  LoginRocketIcon,
  LoginStraightButterFlyIcon,
} from "../Assets/Icons/icons";
import AlertBox from "../Components/AlertBox";

const DefaultLayout = () => {
  const navigate = useNavigate();
  const { errorMessage, errorAlert, successMessage, successAlert } =
    useSelector((state) => state.userLogin);
  const dispatch = useDispatch();

  const closeAlert = () => {
    dispatch({
      type: "USER_LOGIN_REQUEST_FAIL",
    });
  };

  useEffect(() => {
    const token = localStorage.getItem("userToken");
    if (token) {
      navigate("/home");
    }
  }, []);

  return (
    <div className={classes.DefaultLayoutMaindiv}>
      <img src={logo} className={classes.Authlogo} alt="QueensawardLogo" />
      <img
        src={defaultMainImage}
        className={classes.AuthDefaultImage}
        alt="AuthDefaultImage"
      />
      <span className={classes.guestCloudIcon}>
        <CloudRaindBowIcon />
      </span>
      <span className={classes.guestCodeIcon}>
        <LoginCodeIcon />
      </span>
      <span className={classes.guestRocketIcon}>
        <LoginRocketIcon />
      </span>
      <span className={classes.gueststraightButterflyIcon}>
        <LoginStraightButterFlyIcon />
      </span>
      <span className={classes.guestCrossButterFlyIcon}>
        <LoginCrossButterFlyIcon />
      </span>
      <div className={classes.guestChildMaindiv}>
        <Outlet />
      </div>
      {errorMessage && errorAlert && (
        <AlertBox
          message={errorMessage}
          type="error"
          onCloseFunction={closeAlert}
        />
      )}
      {successMessage && successAlert && (
        <AlertBox
          message={successMessage}
          type="sucsess"
          onCloseFunction={closeAlert}
        />
      )}
    </div>
  );
};

export default DefaultLayout;
