import React, { useEffect, useState } from "react";
import classes from "./auth.module.css";
import Carousel from "react-multi-carousel";
import logo from "../../Assets/Images/metaMainLogo.png";
import Ellipse1 from "../../Assets/Images/Ellipse1.png";
import Ellipse2 from "../../Assets/Images/Ellipse2.png";
import Ellipse3 from "../../Assets/Images/Ellipse3.png";
import Ellipse4 from "../../Assets/Images/Ellipse4.png";
import Ellipse5 from "../../Assets/Images/Ellipse5.png";
import leftarrow from "../../Assets/Images/leftarrow.png";
import rightarrow from "../../Assets/Images/rightarrow.png";
import Img1 from "../../Assets/Images/img-1.png";
import Img2 from "../../Assets/Images/img-2.png";
import Img3 from "../../Assets/Images/img-3.png";
import Img4 from "../../Assets/Images/img-4.png";
import Img5 from "../../Assets/Images/img-5.png";
import { Typography, Box, Button } from "@mui/material";
import Slider from "react-slick";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AlertBox from "../../Components/AlertBox";
import {
  getAvatarList,
  pageLoader,
  setSelectedAvatar,
} from "../../Redux/Actions/userActions";

let arr = [
  { id: 1, img: Ellipse1 },
  { id: 2, img: Ellipse2 },
  { id: 3, img: Ellipse3 },
  { id: 4, img: Ellipse4 },
  { id: 5, img: Ellipse5 },
];

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 850 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 850, min: 0 },
    items: 1,
  },
};

function ChooseAvatar() {
    const { errorMessage, errorAlert,userInfo } = useSelector((state) => state.userLogin);
  const dispatch = useDispatch();
  const [activeImage, setActiveImage] = useState(2);
  const [avatarList, setAvatarList] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [isProfile, setIsProfile] = useState(false);
  const { loggedUser } = useSelector((state) => state.userLogin);
  const navigate = useNavigate();
  let _token = JSON.parse(localStorage.getItem("userToken"));
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    // centerPadding: "60px",
    slidesToShow: 5,
    speed: 500,
  };

  const closeAlert = () => {
    dispatch({
      type: "USER_LOGIN_REQUEST_FAIL",
    });
  };

  const CustomButtonGroup = ({ next, previous }) => {
    return (
      <div>
        <img
          src={rightarrow}
          alt="rightarrow"
          className={classes.rightarrow}
          onClick={() => {
            next();
            handleAfterChange();
          }}
        />
        <img
          src={leftarrow}
          alt="leftarrow"
          className={classes.leftarrow}
          onClick={() => {
            previous();
            handleBeforeChange();
          }}
        />
      </div>
    );
  };

  useEffect(() => {
    if (avatarList && avatarList.length > 0) {
      setSelectedItem(avatarList[2]);
    }
  }, [avatarList.length > 0]);

  const handleAfterChange = (previousSlide, _ref, name) => {
    const index = parseInt(activeImage) + 1;
    setActiveImage(
      index < avatarList.length ? index : index - avatarList.length
    );
    const item =
      avatarList[index < avatarList.length ? index : index - avatarList.length];

    setSelectedItem(item);
  };

  const handleBeforeChange = (nextSlide, _ref2) => {
    const index = parseInt(activeImage) - 1;
    setActiveImage(index > -1 ? index : index + avatarList.length);
    const item = avatarList[index > -1 ? index : index + avatarList.length];
    setSelectedItem(item);
  };

  const handleAvatarList = () => {
    dispatch(getAvatarList(setAvatarList));
  };

  const handleSkip = ()=>{
    if (isProfile) {
      navigate(-1);
    } 
    else {
      navigate("/choosefriend");
    };
  }

  useEffect(() => {
    if (window.location.pathname.includes("updateavatar")) {
      setIsProfile(true);
    } else{
      setIsProfile(false);
    }
    handleAvatarList();
  }, []);

  const handleSubmit = () => {
    if (selectedItem) {
      let route = "";
      const userEntry = {
        Id: selectedItem._id,
      };
      if (isProfile) {
        if (sessionStorage.getItem("myrole") === "PARENT") {
          route = "/parent-dashboard"
      } else {               
          route = "/myprofile"
      }
      } else {
        route = "/choosefriend"
      };
      let stu_info = JSON.parse(localStorage.getItem("userToken"));
      let my_student_id = userInfo?.studentInfo?._id || stu_info?.data[0]?._id;
      dispatch(pageLoader(true));
      dispatch(
        setSelectedAvatar(my_student_id, userEntry, navigate,route)
      );
    }
  };
  console.log("userInfo =",userInfo)
  return (
    <div className={classes.chooseavatar}>
      <div className="container pt-3">
        <img
          src={logo}
          alt="Queensaward-logo"
          className={classes.metaverselogo}
        />
        <Box mt={6}>
          <Typography variant="h2" className={classes.welcomemsg}>
            Welcome {loggedUser?.student?.first_name} {loggedUser?.student?.last_name}
          </Typography>
          <Typography variant="h2" className={classes.authHeader}>
            Choose the Avatar that suits you best
          </Typography>
        </Box>
        <div className="slider-container">
          <Carousel
            responsive={responsive}
            autoPlaySpeed={1500}
            draggable={false}
            infinite={true}
            containerClass={classes.SliderContainer}
            arrows={false}
            customButtonGroup={<CustomButtonGroup />}
            // afterChange={(previousSlide, _ref) =>
            //   handleAfterChange(previousSlide, _ref, "left")
            // }
            // beforeChange={handleBeforeChange}
          >
            {Array.isArray(avatarList) &&
              avatarList.map((value, index) => {
                return (
                  <img
                    src={`${process.env.REACT_APP_API_URL}/${value.image}`}
                    alt="avatar"
                    key={index}
                    className={`${classes.SliderImage} ${
                      index === activeImage
                        ? classes.activeImage
                        : classes.inactiveImage
                    }
                    }`}
                  />
                );
              })}
          </Carousel>
        </div>
        <div className={`mt-3 ${classes.loginSubmitBtnContainer}`}>
          {isProfile ?
                  <Button
                  variant="contained"
                  className={`${classes.loginSubmitBtn}`}
                  type="submit"
                  sx={{ marginTop: "10px" }}
                  onClick={() => handleSubmit()}
                >
                  Update
                </Button> :
                          <Button
                          variant="contained"
                          className={`${classes.loginSubmitBtn}`}
                          type="submit"
                          sx={{ marginTop: "10px" }}
                          onClick={() => handleSubmit()}
                        >
                          Save
                        </Button>  
        }
        </div>
      </div>
      <img src={Img1} alt="svgSampleImage1" className={classes.imgone} />
      <img src={Img2} alt="svgSampleImage2" className={classes.imgtwo} />
      <img src={Img3} alt="svgSampleImage3" className={classes.imgthree} />
      <img src={Img4} alt="svgSampleImage4" className={classes.imgfour} />
      <img src={Img5} alt="svgSampleImage5" className={classes.imgfive} />
      <img src={Img5} alt="svgSampleImage6" className={classes.imgfive} />
      {errorMessage && errorAlert && (
        <AlertBox
          message={errorMessage}
          type="error"
          onCloseFunction={closeAlert}
        />
      )}
      <Typography
        className={classes.skipavatar}
        onClick={handleSkip}
      >
        Skip
      </Typography>
    </div>
  );
}

export default ChooseAvatar;
