import React, { useState, useEffect } from "react";
import classes from "./payment.module.css";
import logo from "../../Assets/Images/metaMainLogo.png";
import {
  ColouredStarIcon,
  LoginCrossButterFlyIcon,
  OrangeRocketIcon,
} from "../../Assets/Icons/icons";
import { Typography } from "@mui/material";
// import { subscriptionList } from "../../Utilities/commonConstants";
import SubscriptionCard from "./subscriptionCard";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GenerateOrder,
  getAllSubscriptionList,
} from "../../Redux/Actions/subscriptionAction";

const SubscriptionIndex = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorAlert, setErrorAlert] = useState(false);
  const { subscriptionList, error } = useSelector(
    (state) => state.subscriptionDatas
  );
  const { userInfo } = useSelector((state) => state.userLogin);

  useEffect(() => {
    console.log("userInfo =", userInfo);
    dispatch(
      getAllSubscriptionList(
        setErrorAlert,
        userInfo?.studentInfo?.gst_type
          ? userInfo?.studentInfo?.gst_type
          : userInfo?.students[0]?.gst_type
      )
    );
  }, [userInfo]);

  const handleChoosePack = (item) => {
    const userEntry = {
      user_id: userInfo?._id,
      subscription_id: item?._id,
      subscribed_by: "INDIVIDUAL",
      type: "new",
      student_id: userInfo?.studentInfo?._id,
    };
    dispatch(GenerateOrder(userEntry, setErrorAlert, navigate));
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <img src={logo} className={classes.paymentlogo} alt="QueensawardLogo" />
      {window.location.pathname.includes("/select-subscription") ? (
        <span className={classes.guestOrangeRocketIcon}>
          <OrangeRocketIcon />
        </span>
      ) : null}
      <span className={classes.guestStarIcon}>
        <ColouredStarIcon />
      </span>
      <span className={classes.bigButterFltIcon}>
        <LoginCrossButterFlyIcon />
      </span>
      <div className={classes.SubscriptionMainContainer}>
        <Typography className={classes.paymentMainHeader}>
          Subscription & Payment
        </Typography>
        {userInfo?.students && userInfo?.students[0] && (
          <Typography
            className={`${classes.paymentMainHeader} ${classes.paymentSubHeaderNote}`}
          >
            (Note: Subscriptions can only be done through the student module)
          </Typography>
        )}
        <div className={`mt-4 ${classes.subscriptionsContainer}`}>
          {subscriptionList &&
            subscriptionList.map((subscription, index) => {
              return (
                <SubscriptionCard
                  key={index}
                  index={index % 4}
                  subscription={subscription}
                  handleChoosePack={handleChoosePack}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionIndex;
