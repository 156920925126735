import {
  Box,
  Typography,
  Button,
  Popover,
  MenuItem,
  Container,
  Grid,
  Tabs,
  Tab,
  InputAdornment,
  IconButton,
  Card,
  TextField,
} from "@mui/material";
import classes from "./parent.module.css";
import classes1 from "../Auth/auth.module.css";
import classes2 from "../myprofile/myprofile.module.css";
import Avatar from "../../Assets/Images/new-avatar.png";
import Ellipse from "../../Assets/Images/Ellipse2.png";
import Topic1 from "../../Assets/Images/topic1.png";
import Topic2 from "../../Assets/Images/topic2.png";
import Topic3 from "../../Assets/Images/topic3.png";
import Topic4 from "../../Assets/Images/topic4.png";
import Topic5 from "../../Assets/Images/topic5.png";
import Topic6 from "../../Assets/Images/topic6.png";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {
  Alphabetic,
  Numeric,
  splitFirstLetter,
} from "../../Utilities/commonfunctions";
import "./parent.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CloseEyeIcon,
  Editicon,
  OpenEyeIcon,
  ViloetBackArrow,
} from "../../Assets/Icons/icons";
import MobileVerification from "../Auth/MobileVerification";
import { useDispatch, useSelector } from "react-redux";
import {
  forgotPasswordEmailsend,
  getAllGradeIds,
  getUserByToken,
  registerUser,
  registerUserParent,
  setStudentPasswordParent,
  verifyOtp,
} from "../../Redux/Actions/userActions";
import OTPInput from "react-otp-input";
import AlertBox from "../../Components/AlertBox";
import SuccessAlertBox from "../../Components/SuccessAlertBox";

const allTopics = [
  {
    id: 1,
    img: Topic1,
    content: "Computer Programming",
  },
  {
    id: 2,
    img: Topic2,
    content: "Algorithms",
  },
  {
    id: 3,
    img: Topic3,
    content: "Digital Literacy",
  },
  {
    id: 4,
    img: Topic4,
    content: "Safety",
  },
  {
    id: 5,
    img: Topic5,
    content: "Computer Programming-2",
  },
  {
    id: 6,
    img: Topic6,
    content: "Information Technology",
  },
];

const users = ["User One", "User Two"];

function ParentDashboard() {
  const [value, setValue] = useState("one");
  const [inputs, setInputs] = useState({ age: "-1", name: "", username: "" });
  const [email, setEmail] = useState("");
  const [parent_name, setParentName] = useState("");
  const [password, setPassword] = useState("");
  const [registerSuccess, setRegisterSuccess] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [pageInfo, setPageInfo] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const { otp_data, userInfo } = useSelector((state) => state.userLogin);
  const [time, setTime] = useState(5);
  const [gradeId, setGradeId] = useState("-1");
  const [gradeIdList, setGradeIdList] = useState([]);
  const [disable, setDisable] = useState({
    name: `Resend in ${time}s`,
    state: true,
  });
  const userToken = JSON.parse(localStorage.getItem("userToken"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleSubmit = () => {
    if (inputs.age === "-1") {
      setErrMsg("Please select age");
      return;
    }
    sessionStorage.setItem("student_info", JSON.stringify(inputs));
    dispatch(registerUserParent(inputs, setPageInfo));
  };

  const handleLogin = () => {};

  const handleEmailSubmit = (e) => {
    let title;
    if (
      /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/.test(
        otp_data.username
      )
    ) {
      title = "email";
      // setTitle(title);
    } else {
      title = "phone";
      // setTitle(title);
    }
    const userEntry = {
      username: otp_data.username,
      title: title,
    };
    dispatch(forgotPasswordEmailsend(userEntry));
  };

  const handleEdit = () => {
    setPageInfo(0);
  };

  const handleOtpSubmit = () => {
    if (otp?.length < 4) {
      setErrMsg("Please Enter 4 digit OTP");
    } else {
      const userEntry = {
        username: otp_data.username,
        otp: otp,
        title: "parent",
      };
      let route = "";
      sessionStorage.removeItem("student_info");
      dispatch(verifyOtp(userEntry, navigate, route, setPageInfo, setErrMsg));
    }
  };

  const handleRegisterSubmit = () => {
    if (gradeId === "-1") {
      setErrMsg("Please Select grade");
    } else {
      const userEntry = {
        username: email,
        id: userToken?._id,
        newPassword: password,
        parent_name: parent_name,
        gradeId: gradeId,
      };
      dispatch(setStudentPasswordParent(userEntry, setRegisterSuccess));
    }
  };
  console.log("userInfo =", userInfo);

  const handleAllGrades = () => {
    dispatch(getAllGradeIds(setGradeIdList));
  };

  const handleGetUser = () => {
    if (userToken && userToken?.data[0]) {
      dispatch(getUserByToken(navigate, userToken?.data[0]?._id));
    }
  };

  const handleSuccessAlertClose = () => {
    setRegisterSuccess("");
    setInputs({ age: "-1", name: "", username: "" });
    setPageInfo(0);
    setEmail("");
    setPassword("");
    setOtp("");
    handleGetUser();
  };

  const handleErrAlertClose = () => {
    setErrMsg("");
  };

  useEffect(() => {
    if (pageInfo == 0) {
      setOtp("");
    }
  }, [pageInfo]);

  useEffect(() => {
    if (userInfo) {
      setInputs({ ...inputs, username: userInfo?.phone });
      setEmail(userInfo?.email);
    }
  }, [userInfo]);

  useEffect(() => {
    handleAllGrades();

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Box pb={3} pt={{xs:3,sm:5,md:9}}>
        <Container>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Box className={classes.welcome_box}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={7}>
                    <Typography>Welcome to Queens Award Coding APP</Typography>
                    <span>
                      Lorem ipsum dolor sit amet, consectetur adipi scing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </span>
                    <Button onClick={() => navigate("/select-subscription")}>
                      Explore Subscription Plans
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}></Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <Box className={classes.parent_avatar}>
                      <img src={Avatar} alt="downArrow" className="nav-imgs" style={{height:"100%"}} />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid container mt={5}>
            <Grid item xs={12} sm={12} md={12}>
              <Box className={classes.my_childrens} mb={3}>
                {Array.isArray(userInfo?.students) &&
                  userInfo?.students?.map((datas, i) => (
                    <Box className={classes.student_list_card}>
                      <Grid
                        container
                        mb={i != userInfo?.students?.length - 1 && 2}
                      >
                        <Grid item xs={12} sm={12} md={4}>
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            gap={"15px"}
                            height={"100%"}
                          >
                            {datas?.avatarInfo?.image ? (
                              <img
                                src={`https://api.meta.apps.org.in/api/${datas?.avatarInfo?.image}`}
                                width={"100px"}
                                className={classes.avatar_url}
                                // onClick={() => navigate("/updateavatar")}
                              />
                            ) : (
                              <Typography
                                className={classes2.split_letters}
                                // onClick={() => navigate("/updateavatar")}
                              >
                                {splitFirstLetter(
                                  `${datas?.first_name} ${datas?.last_name}`
                                )}
                              </Typography>
                            )}
                            <Box>
                              <Typography className={classes.profile_name}>
                                {datas?.first_name} {datas?.last_name}
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={"15px"}
                                position={"relative"}
                              >
                                <Typography className={classes.label}>
                                  Age:{" "}
                                  <span className={classes.text}>
                                    {" "}
                                    {datas?.age} Years
                                  </span>{" "}
                                </Typography>
                                <Typography
                                  className={`${classes.label} ${classes.before_line}`}
                                >
                                  Grade:{" "}
                                  <span className={classes.text}>
                                    {datas?.gradeInfo?.grade?.replace(
                                      "_",
                                      " "
                                    ) || datas?.grade_name?.replace("_", " ")}
                                  </span>{" "}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5}></Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={3}
                          sx={{ display:{ xs:"block",sm:"grid",md:"grid"}, placeItems: "center" }}
                        >
                          <Button
                            className={classes.view_more}
                            onClick={() =>
                              navigate("/parent-user", {
                                state: { userTab: i },
                              })
                            }
                          >
                            View More Details
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} mt={{xs:1,sm:2,md:3}}>
                          <Typography className={classes.myprofile_details}>
                          {userInfo?.studentInfo?.gst_type === "B2B"
                          ? "School"
                          : "Certified By"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} mt={{xs:1,sm:2,md:3}}>
                          <Typography className={classes.myprofile_details}>
                            <b>{datas?.schoolInfo?.name}</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} mt={{xs:1,sm:2,md:3}}>
                          <Typography className={classes.myprofile_details}>
                            {" "}
                            {userInfo?.studentInfo?.gst_type === "B2B"
                          ? "School Email"
                          : "Support Email"}:
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} mt={{xs:1,sm:2,md:3}}>
                          <Typography className={classes.myprofile_details}>
                            <b> {datas?.schoolInfo?.email}</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2}>
                          <Typography className={classes.myprofile_details}>
                            {" "}
                            {userInfo?.studentInfo?.gst_type === "B2B"
                          ? "School Phone Number"
                          : "Support Number"}:
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          <Typography className={classes.myprofile_details}>
                            <b> {datas?.schoolInfo?.phone}</b>
                          </Typography>
                        </Grid>
                       {userInfo?.studentInfo?.gst_type === "B2B" &&
                        <>
                         <Grid item xs={12} sm={12} md={2}>
                          <Typography className={classes.myprofile_details}>
                            Class Teacher:
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5}>
                          <Typography className={classes.myprofile_details}>
                            <b>{datas?.schoolInfo?.teacher_name ?? "n/a"}</b>
                          </Typography>
                        </Grid>
                        </>
                       }
                      </Grid>
                      {i != userInfo?.students?.length - 1 && <hr />}
                      {/* <Button
                            className={classes.remove_student}
                          >
                            Remove Student
                          </Button> */}
                    </Box>
                  ))}

                {/* <Grid container>
                <Grid item xs={12} sm={12} md={9}>

                </Grid>
                <Grid item xs={12} sm={12} md={3}></Grid>
            </Grid> */}
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} sm={12} md={6}>
              {pageInfo === 0 && (
                <Box className={classes.add_student}>
                  <Typography className={classes.add_student_header}>
                    Add Student Account
                  </Typography>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="secondary tabs example"
                    className={"my_tabs"}
                  >
                    <Tab
                      value="one"
                      label="Create New Account"
                      className={classes.tab_one}
                    />
                    {/* <Tab
                      value="two"
                      label="Add Existing Account"
                      className={classes.tab_two}
                    /> */}
                  </Tabs>
                  {value === "one" ? (
                    <ValidatorForm
                      useref="form"
                      onSubmit={handleSubmit}
                      className={`mt-5 mb-4 ${classes1.formInputContainer}`}
                    >
                      <div className="mb-4">
                        <TextValidator
                          id="age"
                          select
                          fullWidth
                          placeholder="Enter Student Name"
                          className={`${classes1.TextInput1}`}
                          value={inputs.age}
                          size="small"
                          focused
                          onChange={(e) => {
                            setInputs({ ...inputs, age: e.target.value });
                          }}
                          classes1={{ paper: classes1.menuPaper }}
                          validators={["required"]} // Add this line
                          errorMessages={["Game Name is required"]}
                        >
                          <MenuItem value={"-1"} sx={{ display: "none" }}>
                            <p className={`${classes1.selectAge}`}>
                              Select Age
                            </p>
                          </MenuItem>
                          {[
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                            "6",
                            "7",
                            "8",
                            "9",
                            "10",
                            "10+",
                          ].map((item, index) => {
                            return (
                              <MenuItem value={item}>
                                <p className={`${classes1.selectAge}`}>
                                  {item}
                                </p>
                              </MenuItem>
                            );
                          })}
                        </TextValidator>
                      </div>
                      <div>
                        <TextValidator
                          id="studentname"
                          fullWidth
                          placeholder="Enter Student Name"
                          className={`${classes1.TextInput}`}
                          value={inputs.name}
                          size="small"
                          focused
                          onChange={(e) => {
                            setInputs({ ...inputs, name: e.target.value });
                          }}
                          onKeyPress={(e) => {
                            Alphabetic(e);
                          }}
                          validators={["required"]} // Add this line
                          errorMessages={["Student Name is required"]}
                        />
                      </div>
                      <div className={`mt-4`}>
                        <TextValidator
                          id="studentNumber"
                          fullWidth
                          placeholder="Enter Parents / Guardian's Mobile Number"
                          className={`${classes1.TextInput}`}
                          value={inputs.username}
                          type={"text"}
                          size="small"
                          focused
                          // onChange={(e) => {
                          //   setInputs({ ...inputs, username: e.target.value });
                          // }}
                          onKeyPress={(e) => {
                            Numeric(e);
                          }}
                          inputProps={{ maxLength: 10 }}
                          validators={["required"]} // Add this line
                          errorMessages={["Mobile Number is required"]}
                        />
                      </div>
                      <div
                        className={`mt-3 ${classes1.loginSubmitBtnContainer}`}
                      >
                        <Button
                          variant="contained"
                          className={`${classes1.loginSubmitBtn}`}
                          type="submit"
                          sx={{ marginTop: "12px" }}
                        >
                          Get Secure Code
                        </Button>
                      </div>
                    </ValidatorForm>
                  ) : (
                    <>
                      <ValidatorForm
                        useref="form"
                        onSubmit={handleLogin}
                        className={`mt-5 mb-4 ${classes1.formInputContainer}`}
                      >
                        <div>
                          <TextValidator
                            id="emailInput"
                            fullWidth
                            placeholder="Email ID / Mobile Number"
                            className={`${classes1.TextInput}`}
                            value={email}
                            size="small"
                            focused
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                            validators={["required"]} // Add this line
                            errorMessages={[
                              "Email Id or Mobile Number is required",
                            ]}
                          />
                        </div>
                        <div className={`mt-4`}>
                          <TextValidator
                            id="emailInput"
                            fullWidth
                            placeholder="Password"
                            className={`${classes1.TextInput}`}
                            value={password}
                            type={showPassword ? "text" : "password"}
                            size="small"
                            focused
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                            validators={["required"]} // Add this line
                            errorMessages={["Password is required"]}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  className={classes1.passwordInputAdornment}
                                >
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <CloseEyeIcon />
                                    ) : (
                                      <OpenEyeIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Typography
                            className={classes1.forgotPasswordText}
                            onClick={() => navigate("/forgotpassword")}
                          >
                            Forgot Password?
                          </Typography>
                        </div>
                        <div
                          className={`mt-3 ${classes1.loginSubmitBtnContainer}`}
                        >
                          <Button
                            variant="contained"
                            className={`${classes1.loginSubmitBtn}`}
                            type="submit"
                          >
                            Login
                          </Button>
                        </div>
                      </ValidatorForm>
                    </>
                  )}
                </Box>
              )}
              {pageInfo === 1 && (
                <Box className={classes.add_student}>
                  <Box display={"flex"} alignItems={"center"}>
                    <span onClick={() => setPageInfo(0)}>
                      <ViloetBackArrow />
                    </span>
                    <Typography variant="h2" className={classes.authHeader}>
                      Verify Secure Code
                    </Typography>
                  </Box>
                  <Typography
                    variant="h6"
                    className={`mt-2 ${classes1.authHeaderSubText}`}
                  >
                    Please enter 4 digit Secure code we have sent you in your{" "}
                    <br />
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      {/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}\b/.test(
                        otp_data?.username
                      )
                        ? "Email ID"
                        : "Mobile Number"}
                      <b style={{ marginLeft: "6px" }}>
                        {/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}\b/.test(
                          otp_data?.username
                        )
                          ? ""
                          : "+44 "}
                        {otp_data?.username}{" "}
                        <div
                          className={classes1.edit_icon}
                          onClick={handleEdit}
                        >
                          <Editicon />
                        </div>
                      </b>{" "}
                    </span>
                  </Typography>
                  <Typography className={`${classes1.authHeaderSubText} mb-5`}>
                    Otp:{otp_data?.otp}
                  </Typography>
                  <ValidatorForm
                    useref="form"
                    onSubmit={handleOtpSubmit}
                    className={`mt-5 mb-4 ${classes1.formInputContainer}`}
                  >
                    <div className={`${classes1.otpWrapper}`}>
                      <OTPInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        containerStyle={classes1.otpInputContainer}
                        inputStyle={classes1.otpInput}
                        renderSeparator={<span> </span>}
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                    <Typography
                      variant="h6"
                      className={`mt-4  ${classes1.authHeaderSubText}`}
                    >
                      Didn't Receive OTP ?
                      <b
                        onClick={handleEmailSubmit}
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        {disable.name}
                      </b>
                    </Typography>
                    <div className={`mt-3 ${classes1.loginSubmitBtnContainer}`}>
                      <Button
                        variant="contained"
                        className={`${classes1.loginSubmitBtn}`}
                        type="submit"
                      >
                        Verify
                      </Button>
                    </div>
                  </ValidatorForm>
                </Box>
              )}
              {pageInfo === 2 && (
                <Box className={classes.add_student}>
                  <Typography variant="h2" className={classes1.authHeader}>
                    Register Student Account
                  </Typography>
                  <ValidatorForm
                    useref="form"
                    onSubmit={handleRegisterSubmit}
                    className={`mt-5 mb-4 ${classes1.formInputContainer}`}
                  >
                    <div className="mb-4 mt-4">
                      <TextValidator
                        id="age"
                        select
                        fullWidth
                        placeholder="Enter Student Name"
                        className={`${classes.TextInput1}`}
                        value={gradeId}
                        size="small"
                        focused
                        onChange={(e) => {
                          setGradeId(e.target.value);
                        }}
                        classes={{ paper: classes.menuPaper }}
                        validators={["required"]} // Add this line
                        errorMessages={["Game Name is required"]}
                      >
                        <MenuItem value={"-1"} sx={{ display: "none" }}>
                          <p className={`${classes.selectAge}`}>
                            Select Grade Id
                          </p>
                        </MenuItem>
                        {Array.isArray(gradeIdList) &&
                          gradeIdList.map((datas, index) => {
                            return (
                              <MenuItem value={datas._id}>
                                <p
                                  className={`${classes.selectAge}`}
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {datas.grade?.replaceAll("_", " ")}
                                </p>
                              </MenuItem>
                            );
                          })}
                      </TextValidator>
                    </div>
                    <div>
                      <TextValidator
                        id="nameInput"
                        fullWidth
                        placeholder="Parent / Guardian's Name"
                        className={`${classes1.TextInput}`}
                        value={parent_name}
                        size="small"
                        focused
                        onChange={(e) => {
                          setParentName(e.target.value);
                        }}
                        onKeyPress={(e) => {
                          Alphabetic(e);
                        }}
                        validators={["required"]} // Add this line
                        errorMessages={["Game Name is required"]}
                      />
                    </div>
                    <div className={`mt-4`}>
                      <TextValidator
                        id="emailInput"
                        fullWidth
                        placeholder="Parent / Guardian's Email"
                        className={`${classes1.TextInput}`}
                        value={email}
                        size="small"
                        focused
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        validators={["required"]} // Add this line
                        errorMessages={["Game Name is required"]}
                      />
                    </div>
                    <div className={`mt-4`}>
                      <TextValidator
                        id="emailInput"
                        fullWidth
                        placeholder="Create Password"
                        className={`${classes1.TextInput}`}
                        value={password}
                        type={showPassword ? "text" : "password"}
                        size="small"
                        focused
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        validators={[
                          "required",
                          "matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
                        ]} // Add this line
                        errorMessages={[
                          "Password is required",
                          "Password is not strong",
                        ]}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className={classes1.passwordInputAdornment}
                            >
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? (
                                  <CloseEyeIcon />
                                ) : (
                                  <OpenEyeIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div className={`mt-3 ${classes1.loginSubmitBtnContainer}`}>
                      <Button
                        variant="contained"
                        className={`${classes1.loginSubmitBtn}`}
                        type="submit"
                        sx={{ marginTop: "10px" }}
                      >
                        Verify
                      </Button>
                    </div>
                  </ValidatorForm>
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box className={classes.all_topics}>
                <Typography className={classes.all_topics_header}>
                  All Topics
                </Typography>
                <Grid container spacing={3}>
                  {allTopics.map((data) => {
                    return (
                      <Grid item xs={6} sm={6} md={4}>
                        <Box
                          className={classes.single_topics}
                          key={data.id}
                          textAlign={"center"}
                          sx={{ cursor: "pointer" }}
                        >
                          <img src={data.img} alt="topic" />
                          <Typography
                            width={"95%"}
                            color={"#fff"}
                            fontWeight={"600"}
                          >
                            {data.content}{" "}
                          </Typography>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {registerSuccess && (
        <SuccessAlertBox
          message={registerSuccess}
          handleAlertClose={handleSuccessAlertClose}
        />
      )}
      {errMsg && (
        <AlertBox
          message={errMsg}
          type="error"
          onCloseFunction={handleErrAlertClose}
        />
      )}
    </>
  );
}

export default ParentDashboard;
