import {
  Box,
  Typography,
  Button,
  Popover,
  MenuItem,
  Badge,
} from "@mui/material";
import logo from "../Assets/Images/metaMainLogo.png";
import classes from "./layout.module.css";
import rocket from "../Assets/Images/rocket.png";
import bell from "../Assets/Images/bell.png";
import profileLogo from "../Assets/Images/profile-logo.png";
import downArrow from "../Assets/Images/down-arrow.png";
import Notification1 from "../Assets/Images/notification-1.png";
import Notification2 from "../Assets/Images/notification-2.png";
import "../Pages/dashboard/profile.css";
import { StyledMenu } from "../Components/CommonMenu";
import {
  BlueLogoutIcon,
  ChangePasswordIcon,
  Homeicon,
  MyCodeIcon,
  UserIcon,
} from "../Assets/Icons/icons";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import SuccessModal from "../Components/successModal";
import { useDispatch, useSelector } from "react-redux";
import { splitFirstLetter } from "../Utilities/commonfunctions";
import {
  getAllNotifications,
  updateNotificationStatus,
} from "../Redux/Actions/userActions";
import dayjs from "dayjs";

const dummyNotofication = [
  {
    img: Notification1,
    content: "You have completed Picture Puzzel Game & Recived 50 Diamonds",
    time: "Just Now",
  },
  {
    img: Notification2,
    content: "You have to finish Sequencing Puzzles game for todays Goal",
    time: "30 minutes ago",
  },
  {
    img: Notification1,
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do ",
    time: "1 hour ago",
  },
  {
    img: Notification2,
    content: "You have completed Picture Puzzel Game & Recived 50 Diamonds",
    time: "",
  },
];

function Navbar({ value, handleChange }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logoutModal, setLogoutModal] = useState(false);
  const [myAllNotifications, setMyAllNotifications] = useState([]);
  const [notifications, setNotifications] = useState(dummyNotofication);
  const { userInfo, userRole, error, loggedUser } = useSelector(
    (state) => state.userLogin
  );
  let _token = JSON.parse(localStorage.getItem("userToken"));

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  console.log("myAllNotifications =", myAllNotifications);
  const modalClose = () => {
    setLogoutModal(false);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleMenuClick = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleMenuClose = (event) => {
    setAnchorEl1(null);
  };

  const handleClickMyProfile = (e) => {
    handleMenuClose(e);
    navigate("/myprofile");
  };

  const handleClickChangePassword = (e) => {
    handleMenuClose(e);
    navigate("/change-password");
  };

  const handleClickLogout = (e) => {
    handleMenuClose(e);
    setLogoutModal(true);
  };

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    navigate("/login");
  };

  const handleGetNotifications = () => {
    dispatch(
      getAllNotifications(
        _token?.data[0]?.user,
        setMyAllNotifications,
        _token?.data[0]?._id
      )
    );
  };

  const handleMarkRead = () => {
    dispatch(
      updateNotificationStatus(_token?.data[0]?.user, handleGetNotifications)
    );
  };

  useEffect(() => {
    handleGetNotifications();
  }, [window.location.pathname]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <Box className="myprofile-container">
        <Box width={{ xs: "25%", sm: "18%", md: "14%" }}>
          <img
            src={logo}
            alt="logo"
            width={"100%"}
            onClick={() => navigate("/home")}
          />
        </Box>
        <Box display={{ xs: "none", sm: "block", md: "block" }}>
          <NavLink
            to={"/home"}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "navactive" : "navinactive"
            }
          >
            <Button startIcon={<Homeicon />}> Home</Button>
          </NavLink>
          <NavLink
            to={"/mycode"}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "navactive" : "navinactive"
            }
          >
            <Button startIcon={<MyCodeIcon />}>My Code</Button>
          </NavLink>
        </Box>
        <Box display={"flex"} alignItems={"center"} gap={"10px"}>
          <img src={rocket} alt="rocket" className="nav-imgs rocket-icon" />
          <Badge
            badgeContent={myAllNotifications && myAllNotifications?.length}
            className={classes.notify_badge}
            color="primary"
            mx={1}
          >
            <img
              src={bell}
              alt="bell"
              className="nav-imgs"
              onClick={handleClick}
            />
          </Badge>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Box className="popper-wrapper">
              <Box>
                <Box className="popper-row-one">
                  <Typography>Notifications </Typography>
                  {Array.isArray(myAllNotifications) &&
                    myAllNotifications?.length > 0 && (
                      <span onClick={handleMarkRead}>Mark all as read</span>
                    )}
                </Box>
                <Box className={classes.notification_part}>
                  {Array.isArray(myAllNotifications) &&
                  myAllNotifications?.length > 0 ? (
                    myAllNotifications?.map((data, i) => {
                      return (
                        <Box key={i} className="single-notification">
                          <img src={Notification1} alt="image" />
                          <Box>
                            <Typography fontSize={"14px"}>
                              {data?.message}
                            </Typography>
                            <span>
                              {dayjs(data?.createdAt).format("DD MMMM YYYY")}
                            </span>
                          </Box>
                        </Box>
                      );
                    })
                  ) : (
                    <Typography className={classes.no_notification}>
                      No Notifications
                    </Typography>
                  )}
                </Box>

                {/* <Typography className="read-all">
                                Read all notifications
                            </Typography> */}
              </Box>
            </Box>
          </Popover>
          <div>
            {userInfo?.avatar_url ? (
              <img
                src={`https://api.meta.apps.org.in/api/${userInfo?.avatar_url}`}
                className={classes.avatar_url}
                style={{ width: "50px", border: "5px solid #854dff" }}
                onClick={handleMenuClick}
              />
            ) : (
              <Typography
                className={classes.split_letters_two}
                onClick={handleMenuClick}
              >
                {splitFirstLetter(
                  `${userInfo?.studentInfo?.first_name} ${userInfo?.studentInfo?.last_name}`
                )}
              </Typography>
            )}
            <img
              src={downArrow}
              alt="downArrow"
              className="nav-imgs"
              style={{ marginLeft: "10px" }}
              onClick={handleMenuClick}
            />
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl1}
              open={anchorEl1 ? true : false}
              onClose={handleMenuClose}
            >
              <MenuItem
                onClick={handleClickMyProfile}
                disableRipple
                style={{ height: "40px" }}
              >
                <UserIcon />
                <span className={classes.MenuItemText}>My Profile</span>
              </MenuItem>
              <MenuItem
                onClick={handleClickChangePassword}
                disableRipple
                style={{ height: "40px" }}
              >
                <ChangePasswordIcon />
                <span className={classes.MenuItemText}>Change Password</span>
              </MenuItem>
              <MenuItem
                onClick={handleClickLogout}
                disableRipple
                style={{ height: "40px" }}
              >
                <BlueLogoutIcon />
                <span className={classes.MenuItemText}>Logout</span>
              </MenuItem>
            </StyledMenu>
          </div>
        </Box>
      </Box>
      {logoutModal && (
        <SuccessModal
          modalClose={modalClose}
          width={335}
          height={153}
          type={"logout"}
          callback={handleLogout}
        />
      )}
    </>
  );
}

export default Navbar;
